<template>
  <div>
    <svg
      v-if="name === 'dashboard'"
      width="20"
      height="20"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16667 6.50033C1.72464 6.50033 1.30072 6.32473 0.988155 6.01217C0.675595 5.69961 0.5 5.27569 0.5 4.83366V2.33366C0.5 1.89163 0.675595 1.46771
    0.988155 1.15515C1.30072 0.842587 1.72464 0.666992 2.16667 0.666992H5.5C5.94203 0.666992 6.36595 0.842587
    6.67851 1.15515C6.99107 1.46771 7.16667 1.89163 7.16667 2.33366V4.83366C7.16667 5.27569 6.99107 5.69961 6.67851 6.01217C6.36595 6.32473 5.94203 6.50033 5.5 6.50033H2.16667ZM2.16667 4.83366H5.5V2.33366H2.16667V4.83366ZM2.16667 17.3337C1.72464 17.3337 1.30072 17.1581 0.988155 16.8455C0.675595 16.5329 0.5 16.109 0.5 15.667V9.00033C0.5 8.5583 0.675595 8.13438 0.988155 7.82181C1.30072 7.50925 1.72464 7.33366 2.16667 7.33366H5.5C5.94203 7.33366 6.36595 7.50925 6.67851 7.82181C6.99107 8.13438 7.16667 8.5583 7.16667 9.00033V15.667C7.16667 16.109 6.99107 16.5329 6.67851 16.8455C6.36595 17.1581 5.94203 17.3337 5.5 17.3337H2.16667ZM2.16667 15.667H5.5V9.00033H2.16667V15.667ZM8.83333 15.667C8.83333 16.109 9.00893 16.5329 9.32149 16.8455C9.63405 17.1581 10.058 17.3337 10.5 17.3337H13.8333C14.2754 17.3337 14.6993 17.1581 15.0118 16.8455C15.3244 16.5329 15.5 16.109 15.5 15.667V14.0003C15.5 13.5583 15.3244 13.1344 15.0118 12.8218C14.6993 12.5093 14.2754 12.3337 13.8333 12.3337H10.5C10.058 12.3337 9.63405 12.5093 9.32149 12.8218C9.00893 13.1344 8.83333 13.5583 8.83333 14.0003V15.667ZM13.8333 15.667H10.5V14.0003H13.8333V15.667ZM10.5 10.667C10.058 10.667 9.63405 10.4914 9.32149 10.1788C9.00893 9.86628 8.83333 9.44235 8.83333 9.00033V2.33366C8.83333 1.89163 9.00893 1.46771 9.32149 1.15515C9.63405 0.842587 10.058 0.666992 10.5 0.666992H13.8333C14.2754 0.666992 14.6993 0.842587 15.0118 1.15515C15.3244 1.46771 15.5 1.89163 15.5 2.33366V9.00033C15.5 9.44235 15.3244 9.86628 15.0118 10.1788C14.6993 10.4914 14.2754 10.667 13.8333 10.667H10.5ZM10.5 9.00033H13.8333V2.33366H10.5V9.00033Z"
        :fill="color"
      />
    </svg>


    <svg v-if="name === 'apply to camp'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7801 16.8267C18.5501 14.1867 19.4659 8.99001 16.8259 5.22001C14.1868 1.45001 8.9901 0.534173 5.2201 3.17501C1.4501 5.81334 0.534268 11.01 3.17344 14.78C5.81344 18.55 11.0101 19.4658 14.7801 16.8267Z"
            :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.7797 16.8267L5.21973 3.17418" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.3145 4.56084C13.1728 9.47417 9.22449 12.2392 2.72949 14.0733" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.8838 1.71333C11.228 6.08 13.7105 9.625 18.0888 12.005" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.91113 7.99584C5.89697 9.81251 8.37863 13.3575 9.11697 18.2867" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg v-if="name === 'profile'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0003 1.66666C5.39783 1.66666 1.66699 5.3975 1.66699 10C1.66699 14.6025 5.39783 18.3333 10.0003 18.3333C14.6028
      18.3333 18.3337 14.6025 18.3337 10C18.3337 5.3975 14.6028 1.66666 10.0003 1.66666Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.55957 15.2883C3.55957 15.2883 5.41707 12.9167 10.0004 12.9167C14.5837 12.9167 16.4421 15.2883 16.4421 15.2883"
            :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 10C10.663 10 11.2989 9.73661 11.7678 9.26777C12.2366 8.79893 12.5 8.16304 12.5 7.5C12.5 6.83696 12.2366 6.20107 11.7678 5.73223C11.2989
       5.26339 10.663 5 10 5C9.33696 5 8.70107 5.26339 8.23223 5.73223C7.76339 6.20107 7.5 6.83696 7.5 7.5C7.5 8.16304 7.76339 8.79893 8.23223 9.26777C8.70107
        9.73661 9.33696 10 10 10V10Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


    <svg
      v-if="name === 'logout'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H9.16667C9.625 17.5 10 17.125 10 16.6667C10 16.2083 9.625 15.8333 9.16667 15.8333H4.16667V4.16667Z"
        :fill="color"
      />
      <path
        d="M17.2083 9.70834L14.8833 7.38334C14.8254 7.3238 14.751 7.2829 14.6696 7.26589C14.5883 7.24888 14.5037 7.25653 14.4268 7.28785C14.3498 7.31917 14.284 7.37274 14.2376 7.44171C14.1913 7.51068 14.1666 7.59191 14.1667 7.675V9.16667H8.33333C7.875 9.16667 7.5 9.54167 7.5 10C7.5 10.4583 7.875 10.8333 8.33333 10.8333H14.1667V12.325C14.1667 12.7 14.6167 12.8833 14.875 12.6167L17.2 10.2917C17.3667 10.1333 17.3667 9.86667 17.2083 9.70834Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="name === 'trash'"
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58 4.94L8.91333 6.09333L0.826666 1.42666L1.49333 0.273331L3.52 1.44L4.42667 1.19333L7.31333 2.86L7.56 3.77333L9.58 4.94ZM0 11.6667V3.66666H3.38L8 6.33333V11.6667C8 12.0203 7.85952 12.3594 7.60947 12.6095C7.35943 12.8595 7.02029 13 6.66667 13H1.33333C0.979711 13 0.640573 12.8595 0.390524 12.6095C0.140476 12.3594 0 12.0203 0 11.6667ZM1.33333 11.6667H6.66667V7.13333L2.97333 5H1.33333V11.6667Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="name === 'arrow-back'"
      width="19"
      height="8"
      viewBox="0 0 19 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM19 3.5L1 3.5V4.5L19 4.5V3.5Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="name === 'edit'"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8336 15.6667H1.16691C0.9459 15.6667 0.733939 15.7545 0.577658 15.9107C0.421378 16.067 0.333581 16.279 0.333581 16.5C0.333581 16.721 0.421378 16.933 0.577658 17.0893C0.733939 17.2455 0.9459 17.3333 1.16691 17.3333H12.8336C13.0546 17.3333 13.2666 17.2455 13.4228 17.0893C13.5791 16.933 13.6669 16.721 13.6669 16.5C13.6669 16.279 13.5791 16.067 13.4228 15.9107C13.2666 15.7545 13.0546 15.6667 12.8336 15.6667ZM1.16691 14H1.24191L4.71691 13.6833C5.09758 13.6454 5.45361 13.4777 5.72525 13.2083L13.2252 5.70833C13.5163 5.4008 13.6737 4.99042 13.6627 4.56711C13.6518 4.1438 13.4735 3.74209 13.1669 3.45L10.8836 1.16667C10.5856 0.886745 10.1951 0.726131 9.78636 0.715375C9.37764 0.70462 8.97923 0.844473 8.66691 1.10833L1.16691 8.60833C0.897552 8.87997 0.729834 9.236 0.691914 9.61666L0.333581 13.0917C0.322355 13.2137 0.338193 13.3368 0.379965 13.452C0.421737 13.5672 0.488416 13.6718 0.575247 13.7583C0.653114 13.8356 0.745461 13.8967 0.846992 13.9381C0.948523 13.9796 1.05724 14.0006 1.16691 14ZM9.72525 2.33333L12.0002 4.60833L10.3336 6.23333L8.10025 4L9.72525 2.33333ZM2.30858 9.75833L7.00025 5.1L9.25025 7.35L4.58358 12.0167L2.08358 12.25L2.30858 9.75833Z"
        :fill="color"
        fill-opacity="0.76"
      />
    </svg>
    <svg
      v-if="name === 'confirm-delete'"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30"
        cy="30"
        r="28.5"
        fill="#FDFFFC"
        :stroke="color"
        stroke-width="3"
      />
      <path
        d="M25.8262 27.4491V37.6532C25.8262 37.9915 25.9728 38.3159 26.2337 38.5551C26.4947 38.7943 26.8486 38.9287 27.2176 38.9287C27.5867 38.9287 27.9406 38.7943 28.2016 38.5551C28.4625 38.3159 28.6091 37.9915 28.6091 37.6532V27.4491C28.6091 27.1108 28.4625 26.7864 28.2016 26.5472C27.9406 26.308 27.5867 26.1736 27.2176 26.1736C26.8486 26.1736 26.4947 26.308 26.2337 26.5472C25.9728 26.7864 25.8262 27.1108 25.8262 27.4491Z"
        :fill="color"
      />
      <path
        d="M32.784 26.1736C33.1531 26.1736 33.507 26.308 33.768 26.5472C34.0289 26.7864 34.1755 27.1108 34.1755 27.4491V37.6532C34.1755 37.9915 34.0289 38.3159 33.768 38.5551C33.507 38.7943 33.1531 38.9287 32.784 38.9287C32.415 38.9287 32.0611 38.7943 31.8001 38.5551C31.5392 38.3159 31.3926 37.9915 31.3926 37.6532V27.4491C31.3926 27.1108 31.5392 26.7864 31.8001 26.5472C32.0611 26.308 32.415 26.1736 32.784 26.1736Z"
        :fill="color"
      />
      <path
        d="M35.5673 19.7959H43.9161C44.2851 19.7959 44.6391 19.9303 44.9 20.1695C45.161 20.4087 45.3076 20.7331 45.3076 21.0714C45.3076 21.4097 45.161 21.7341 44.9 21.9733C44.6391 22.2125 44.2851 22.3469 43.9161 22.3469H42.3771L40.2844 39.6326C40.0953 41.1927 39.2853 42.634 38.0093 43.6808C36.7334 44.7276 35.0811 45.3063 33.3688 45.3061H26.6341C24.9217 45.3063 23.2695 44.7276 21.9935 43.6808C20.7176 42.634 19.9075 41.1927 19.7185 39.6326L17.623 22.3469H16.0868C15.7177 22.3469 15.3638 22.2125 15.1029 21.9733C14.8419 21.7341 14.6953 21.4097 14.6953 21.0714C14.6953 20.7331 14.8419 20.4087 15.1029 20.1695C15.3638 19.9303 15.7177 19.7959 16.0868 19.7959H24.4356C24.4356 18.4427 25.022 17.145 26.0658 16.1882C27.1096 15.2314 28.5253 14.6938 30.0014 14.6938C31.4776 14.6938 32.8933 15.2314 33.9371 16.1882C34.9809 17.145 35.5673 18.4427 35.5673 19.7959ZM30.0014 17.2449C29.2634 17.2449 28.5555 17.5136 28.0336 17.992C27.5117 18.4705 27.2185 19.1193 27.2185 19.7959H32.7844C32.7844 19.1193 32.4912 18.4705 31.9693 17.992C31.4474 17.5136 30.7395 17.2449 30.0014 17.2449ZM20.4254 22.3469L22.4847 39.352C22.5984 40.2879 23.0845 41.1524 23.8501 41.7803C24.6156 42.4081 25.6069 42.7552 26.6341 42.7551H33.3688C34.3955 42.7546 35.3861 42.4072 36.151 41.7794C36.916 41.1516 37.4017 40.2874 37.5153 39.352L39.5803 22.3469H20.4282H20.4254Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="name === 'arrowDown'"
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.46967 18.5303C5.76256 18.8232 6.23744 18.8232 6.53033 18.5303L11.3033 13.7574C11.5962 13.4645 11.5962 12.9896
       11.3033 12.6967C11.0104 12.4038 10.5355 12.4038 10.2426 12.6967L6 16.9393L1.75736 12.6967C1.46447 12.4038 0.989593 12.4038 0.6967
       12.6967C0.403806 12.9896 0.403806 13.4645 0.6967 13.7574L5.46967 18.5303ZM5.25 3.27836e-08L5.25 18L6.75 18L6.75 -3.27836e-08L5.25 3.27836e-08Z"
        :fill="color"
      />
    </svg>

    <svg
      v-if="name === 'arrowUp'"
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.46967 0.469669C5.76256 0.176777 6.23744 0.176777 6.53033 0.469669L11.3033 5.24264C11.5962 5.53553 11.5962
      6.01041 11.3033 6.3033C11.0104 6.59619 10.5355 6.59619 10.2426 6.3033L6 2.06066L1.75736 6.3033C1.46447 6.59619 0.989593
      6.59619 0.6967 6.3033C0.403806 6.01041 0.403806 5.53553 0.6967 5.24264L5.46967 0.469669ZM5.25 19L5.25 1L6.75 1L6.75 19L5.25 19Z"
        :fill="color"
      />
    </svg>
    <svg
      v-if="name === 'thumbs-up'"
      width="55"
      height="52"
      viewBox="0 0 55 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00937 23.4663C1.9794 23.1211 2.02151 22.7734 2.13302 22.4454C2.24453 22.1173 2.42302 21.8161 2.65715 21.5606C2.89128 21.3052 3.17594 21.1013 3.49308 20.9617C3.81021 20.8222 4.15289 20.7501 4.49937 20.75H9.27312C9.93616 20.75 10.572 21.0134 11.0409 21.4822C11.5097 21.9511 11.7731 22.587 11.7731 23.25V47C11.7731 47.663 11.5097 48.2989 11.0409 48.7678C10.572 49.2366 9.93616 49.5 9.27312 49.5H6.56562C5.93991 49.5002 5.33688 49.2657 4.87565 48.8428C4.41441 48.42 4.12849 47.8396 4.07437 47.2163L2.00937 23.4663Z"
        fill="white"
        :stroke="color"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2734 22.4687C19.2734 21.4237 19.9234 20.4887 20.8672 20.0437C22.9284 19.0712 26.4397 17.1175 28.0234 14.4762C30.0647 11.0712 30.4497 4.91999 30.5122 3.51124C30.5209 3.31374 30.5159 3.11624 30.5422 2.92124C30.8809 0.479993 35.5922 3.33124 37.3984 6.34624C38.3797 7.98124 38.5047 10.13 38.4022 11.8087C38.2909 13.6037 37.7647 15.3375 37.2484 17.06L36.1484 20.7312H49.7197C50.1059 20.7312 50.487 20.8207 50.8328 20.9927C51.1787 21.1646 51.48 21.4144 51.7131 21.7224C51.9463 22.0303 52.1049 22.3881 52.1765 22.7677C52.2481 23.1472 52.2308 23.5382 52.1259 23.91L45.4134 47.68C45.2653 48.2042 44.9499 48.6657 44.5153 48.9943C44.0807 49.3228 43.5507 49.5004 43.0059 49.5H21.7734C21.1104 49.5 20.4745 49.2366 20.0057 48.7678C19.5368 48.2989 19.2734 47.663 19.2734 47V22.4687Z"
        fill="white"
        :stroke="color"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name === 'oops'"
      width="55"
      height="49"
      viewBox="0 0 55 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2935 4.48906L2.47138 39.2499C2.04208 39.9933 1.81492 40.8363 1.81252 41.6948C1.81012 42.5533 2.03254 43.3974 2.45768 44.1433C2.88281 44.8891 3.49583 45.5106 4.23574 45.946C4.97566 46.3814 5.81668 46.6155 6.67513 46.6249H48.3193C49.1777 46.6155 50.0188 46.3814 50.7587 45.946C51.4986 45.5106 52.1116 44.8891 52.5368 44.1433C52.9619 43.3974 53.1843 42.5533 53.1819 41.6948C53.1795 40.8363 52.9524 39.9933 52.5231 39.2499L31.701 4.48906C31.2627 3.76657 30.6457 3.16923 29.9093 2.75467C29.173 2.3401 28.3422 2.12231 27.4972 2.12231C26.6522 2.12231 25.8214 2.3401 25.0851 2.75467C24.3488 3.16923 23.7317 3.76657 23.2935 4.48906V4.48906Z"
        :stroke="color"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.5 17.125V26.9583"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.5 36.7915H27.5236"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg v-if="name === 'start'" :width="width" :height="height" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 2.33325V33.1666" :stroke="color" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 5.6665H29.9542L32 8.99984L29.9542 12.3332H17V5.6665ZM17 17.3332H4.04583L2 20.6665L4.04583 23.9998H17V17.3332Z"
            :fill="color" :stroke="color" stroke-width="4" stroke-linejoin="round"/>
      <path d="M10.334 34H23.6673" :stroke="color" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg  v-if="name === 'end'" :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 0V18H2.25V12H17.25V0H0.75ZM2.25 1.5H4.5V3.75H6.75V1.5H9V3.75H11.25V1.5H13.5V3.75H15.75V6H13.5V8.25H15.75V10.5H13.5V8.25H11.25V10.5H9V8.25H6.75V10.5H4.5V8.25H2.25V6H4.5V3.75H2.25V1.5ZM4.5 6V8.25H6.75V6H4.5ZM6.75 6H9V3.75H6.75V6ZM9 6V8.25H11.25V6H9ZM11.25 6H13.5V3.75H11.25V6Z" :fill="color"/>
    </svg>

    <svg v-if="name === 'key'" width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4H11.65C11.2381 2.83048 10.4733 1.81762 9.46134 1.10116C8.44934 0.384703 7.23994 -4.57935e-05 6 4.08807e-09C2.69 4.08807e-09 0 2.69 0 6C0 9.31 2.69 12 6 12C7.23994 12 8.44934 11.6153 9.46134 10.8988C10.4733 10.1824 11.2381 9.16952 11.65 8H12L14 10L16 8L18 10L22 5.96L20 4ZM6 9C4.35 9 3 7.65 3 6C3 4.35 4.35 3 6 3C7.65 3 9 4.35 9 6C9 7.65 7.65 9 6 9Z"
            :fill="color"/>
    </svg>

    <svg v-if="name === 'switch'" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="12" rx="6" :fill="color"/>
      <circle cx="17.9984" cy="6.00039" r="3.6" fill="white"/>
    </svg>

    <svg v-if="name === 'change-password'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7336_3433)">
        <path d="M11.7352 11.7327H10.6685V10.666H11.7352V11.7327ZM8.53516 11.7327H9.60182V10.666H8.53516V11.7327ZM13.8685 11.7327H12.8018V10.666H13.8685V11.7327Z"
              :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.2 6.4V3.73333C3.2 3.24307 3.29657 2.7576 3.48418 2.30465C3.6718 1.8517 3.9468 1.44014 4.29347 1.09347C4.64014 0.746796 5.0517 0.471801 5.50465 0.284183C5.9576 0.0965655 6.44307 0 6.93333 0C7.4236 0 7.90907 0.0965655 8.36202 0.284183C8.81497 0.471801 9.22653 0.746796 9.5732 1.09347C9.91987 1.44014 10.1949 1.8517 10.3825 2.30465C10.5701 2.7576 10.6667 3.24307 10.6667 3.73333V6.4H12.2667C12.691 6.4 13.098 6.56857 13.398 6.86863C13.6981 7.16869 13.8667 7.57565 13.8667 8V8.58667C14.4695 8.70907 15.0115 9.03612 15.4007 9.5124C15.79 9.98867 16.0027 10.5849 16.0027 11.2C16.0027 11.8151 15.79 12.4113 15.4007 12.8876C15.0115 13.3639 14.4695 13.6909 13.8667 13.8133V14.4C13.8667 14.8243 13.6981 15.2313 13.398 15.5314C13.098 15.8314 12.691 16 12.2667 16H1.6C1.17565 16 0.768687 15.8314 0.468629 15.5314C0.168571 15.2313 0 14.8243 0 14.4L0 8C0 7.57565 0.168571 7.16869 0.468629 6.86863C0.768687 6.56857 1.17565 6.4 1.6 6.4H3.2ZM4.26667 3.73333C4.26667 3.02609 4.54762 2.34781 5.04772 1.84772C5.54781 1.34762 6.22609 1.06667 6.93333 1.06667C7.64058 1.06667 8.31886 1.34762 8.81895 1.84772C9.31905 2.34781 9.6 3.02609 9.6 3.73333V6.4H4.26667V3.73333ZM9.06667 9.6C8.64232 9.6 8.23535 9.76857 7.9353 10.0686C7.63524 10.3687 7.46667 10.7757 7.46667 11.2C7.46667 11.6243 7.63524 12.0313 7.9353 12.3314C8.23535 12.6314 8.64232 12.8 9.06667 12.8H13.3333C13.7577 12.8 14.1646 12.6314 14.4647 12.3314C14.7648 12.0313 14.9333 11.6243 14.9333 11.2C14.9333 10.7757 14.7648 10.3687 14.4647 10.0686C14.1646 9.76857 13.7577 9.6 13.3333 9.6H9.06667Z"
              :fill="color"/>
      </g>
      <defs>
        <clipPath id="clip0_7336_3433">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>

    <svg v-if="name === 'settings'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 15.8333H7.5M2.5 4.16667H5.83333H2.5ZM17.5 4.16667H9.16667H17.5ZM2.5 10H12.5H2.5ZM17.5 10H15.8333H17.5ZM2.5 15.8333H4.16667H2.5Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M7.5026 5.83333C8.42308 5.83333 9.16927 5.08714 9.16927 4.16667C9.16927 3.24619 8.42308 2.5 7.5026 2.5C6.58213 2.5 5.83594 3.24619 5.83594 4.16667C5.83594 5.08714 6.58213 5.83333 7.5026 5.83333Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M14.1667 11.6667C15.0871 11.6667 15.8333 10.9205 15.8333 9.99999C15.8333 9.07952 15.0871 8.33333 14.1667 8.33333C13.2462 8.33333 12.5 9.07952 12.5 9.99999C12.5 10.9205 13.2462 11.6667 14.1667 11.6667Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M5.83073 17.5C6.7512 17.5 7.4974 16.7538 7.4974 15.8333C7.4974 14.9129 6.7512 14.1667 5.83073 14.1667C4.91025 14.1667 4.16406 14.9129 4.16406 15.8333C4.16406 16.7538 4.91025 17.5 5.83073 17.5Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Icons",
  props: {
    name: [String],
    color: {
      type: [String],
      default: "#004AAD",
    },
    width: [String],
    height: [String]
  },
};
</script>

<style scoped></style>
