<template>
<div class="tw-w-full tw-flex tw-flex-col ">
  <h5 class="parent tw-pt-16 tw-pb-5">Parent</h5>
  <div class="tw-flex tw-w-full tw-bg-white tw-flex-col tw-p-5">
    <div class="tw-w-full tw-flex tw-justify-between tw-py-10">
      <div class="tw-flex tw-w-auto tw-items-center">
        <img class="parent-img" v-if="parentData.profileImage" :src="parentData.profileImage" alt="profile image"/>
        <div v-else class="parent-avatar">{{parentData.firstname.charAt(0)}}</div>
        <h6 class="parent-name tw-ml-10">{{parentData.firstname}} {{parentData.lastname}}</h6>
      </div>
      <v-btn text @click="routeToSetting"><icons name="edit" color="#F66B05"/> </v-btn>
    </div>
    <v-divider></v-divider>
    <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-py-10">
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
         <h6 class="parent-header">First Name</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.firstname}}</h6>
      </v-col>
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
        <h6 class="parent-header">Last Name</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.lastname}}</h6>
      </v-col>
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
        <h6 class="parent-header">Email</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.email}}</h6>
      </v-col>
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
        <h6 class="parent-header">Phone Number</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.phoneNumber}}</h6>
      </v-col>
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
        <h6 class="parent-header">Country</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.address.country}}</h6>
      </v-col>
      <v-col sm="12" lg="2" class="tw-flex tw-flex-col">
        <h6 class="parent-header">County</h6>
        <h6 class="parent-desc tw-mt-5">{{parentData.address.county}}</h6>
      </v-col>
    </v-row>
  </div>
  <h5 class="parent tw-pt-5 tw-pb-5">Children | Ward</h5>
  <v-progress-linear
    indeterminate
    color="#F66B05"
    v-if="loading"
  ></v-progress-linear>
    <div v-else class="children tw-flex-wrap">
      <div class="child-card  tw-bg-white tw-flex tw-flex-row tw-items-center tw-p-5" v-for="(child,index) in children" :key="index">
        <div class="child-avatar">{{child.firstname.charAt(0)}}</div>
        <h6 class="parent-name tw-ml-10">{{child.firstname}} {{child.lastname}}</h6>
      </div>

  </div>

</div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";

import { getAllGuardianCandidate } from "@/services/api/APIService";
export default {
  name: "GuardianProfile",
  components: { Icons },
  data(){
    return{
      parentData:{},

      children:[],
      loading : false
    }
  },
  methods:{
    routeToSetting(){
      sessionStorage.setItem("dashboardComponent", "settings");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", 'settings');
      this.$router.push({name: 'Settings'})
      window.location.reload()
    },
   async getAllGuardianCandidate(){
      this.loading = true
     await getAllGuardianCandidate(this.parentData.id).then(res =>{
        this.loading = false
       this.children = res.data
       console.log(res.data)
      }).catch((err) => {
        this.loading = false
        console.log(err, "in compo err");
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        })
      })
    }
  },
 async created() {
    this.parentData = JSON.parse(sessionStorage.getItem('parentData'))
   await this.getAllGuardianCandidate()
  }
};
</script>

<style scoped lang="scss">
.parent{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.parent-img{
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
.parent-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.parent-header{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.parent-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.child-card{
  width: 443px;
  height: 200px;
  border-radius: 10px;
  margin-right: 2rem;
  margin-top: 2rem;

  @media screen and(max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
}

.children{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;

}

.scroll{
  overflow-y: scroll;
  overflow-x: hidden;

}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  border-radius: 6px;
}

.child-avatar{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F66B05;
  color: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.parent-avatar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F66B05;
  color: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
</style>